<template>
  <div class="login-page grid grid-cols-12 grid-rows-12 pt-10">
    <!-- <div
      class="col-start-2 col-end-12 pt-10 sm:col-start-4 sm:col-end-10 md:col-end-8"
    > -->
    <div class="col-start-1 col-end-13">
      <div class="text-left pb-5">
        <b class="text-2xl">เข้าสู่ระบบ</b>
      </div>
      <div v-for="inp in inputInformation" :key="inp.id" class="pb-5">
        <InputNdbx
          :id="inp.id"
          :label="inp.label"
          :type="inp.type"
          v-model="inp.value"
        />
      </div>
      <span
        v-if="!classInvalid"
        class="block text-sm text-red-500 py-1 text-left font-medium text-slate-700"
        >{{ errorMessage }}</span
      >
      <div class="text-left">
        <button
          class="border-ra rounded pl-2 pr-2 mt-2 bg-green-400 text-white hover:bg-green-500 h-8"
          @click="actionLogin"
        >
          เข้าสู่ระบบ
        </button>
      </div>
    </div>
    <div></div>
  </div>

  <!-- <div class="login-page p-5 sm:p-12">
    <div
      v-for="inp in inputInformation"
      :key="inp.id"
      class="pb-4 md:container md:max-w-screen-sm mr-auto ml-auto"
    >
      <InputNdbx
        :id="inp.id"
        :label="inp.label"
        :type="inp.type"
        v-model="inp.value"
      />
    </div>
    <button
      class="border-ra rounded p-2 mt-2 bg-green-400 text-white hover:bg-green-500"
      @click="actionLogin"
    >
      เข้าสู่ระบบ
    </button>
    <div></div>
  </div> -->
</template>

<script>
import InputNdbx from "@/components/ndbx-elements/Input.vue";

export default {
  name: "Login",
  data() {
    return {
      inputInformation: [
        { id: "username", label: "Username", type: "text", value: "" },
        { id: "password", label: "Password", type: "password", value: "" },
      ],
      valueTest: "",
      classInvalid: true,
      errorMessage: "",
    };
  },
  components: {
    InputNdbx,
  },
  methods: {
    async actionLogin() {
      const loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        lockScroll: true,
        zIndex: 9999,
        loader: "dots",
        color: "#006192",
      });

      const params = new URLSearchParams();
      params.append("username", this.inputInformation[0].value);
      params.append("password", this.inputInformation[1].value);

      const data = await this.$store.dispatch("login/userLogin", params);
      if (data && data.status === false) {
        console.log("err");
        console.log(data);
        loader.hide();
        this.classInvalid = false;
        this.errorMessage = data.message;
      } else {
        loader.hide();
        this.classInvalid = true;
        this.errorMessage = "";
        this.$router.push({ name: "sale.genqr" });
        console.log(data);
      }
    },
  },
};
</script>

<style>
.login-page {
  max-height: calc(100vh - 9.5rem);
}
</style>
