<template>
  <!-- RouterView -->
  <router-view :key="$route.name" />
</template>

<script>
export default {
  name: "QrcodePage",
};
</script>
